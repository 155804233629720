
import React from "react";
// import MyRouter from './router'
import cookie from 'react-cookies';
import session from '../src/assets/functions/session';
import functions from '../src/assets/functions/common_functions';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { isMobile } from "react-device-detect";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import {configureStore} from '../src/store';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
// In your component file or _app.js
// import 'swiper/swiper-bundle.css';

// import url from "./assets/url";
// import '../src/assets/css/style.css';
import LottieLoader from '../LottieLoader';
import '../src/assets/css/animation_style.css';
import '../src/assets/css/program_main_animation.css';
import '../public/assets/css/main.css';
import '../public/assets/css/style_ftm.css';
import '../public/assets/css/responsive.css';
import '../public/assets/css/login_process.css';

import '../src/assets/css/style.css';
import '../public/assets/css/custom_next.css';

import '../public/assets/css/slider.css';
import { initFacebookPixel } from '../lib/facebookPixel';
import config from './../src/config'
// require('dotenv').config()
var QRCode = require('qrcode.react');
var base64 = require('base-64');

// Sentry.init({   
//   dsn: "https://38ff0aa96d7c42c68eef5982ade097de@o4505117900406784.ingest.us.sentry.io/4505124548902912",
//   // beforeSend(event, hint) {
//   //   // Check if it is an exception, and if so, show the report dialog
//   //   if (event.exception) {
//   //     Sentry.showReportDialog({ eventId: event.event_id });
//   //     const feedback = {
//   //       event_id: event.event_id
//   //     }
//   //     Sentry.captureUserFeedback(feedback);
//   //   }
//   //   return event;
//   // },

//   // Add browser profiling integration to the list of integrations
//   integrations: [new Integrations.BrowserTracing(),
//                  new Sentry.Replay({
//                   stickySession: true,
//                   mutationLimit: 1000,
//                   mutationBreadcrumbLimit:100,
//                   minReplayDuration: 5000
//                 })],
//   ignoreErrors: ["Maximum call stack size exceeded", "RangeError", /^call stack size exceeded$/, 'clarity', 'shadowRoot', "Failed to execute 'removeChild' on 'Node'", 'isTouched' , 'querySelectorAll', 'Network Error', 'Failed to fetch', 'Load failed', 'NotFoundError', 'Request aborted', 'Object captured as exception with keys: code, message, name', 'too much recursion'],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment:url.staging,
//   profilesSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 0% while in development and then sample at a lower rate in production.
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   release: 'FamilyOneFront@6.14.11',
// });

// To federated sign in from Facebook
class App extends React.Component {
  constructor(props) {
    super(props);
  
    // Initialize default values for server-side rendering
    let search = "";
    let encoded_email = "";
    let qr_value = "https://app.family.one";
  
    // Check if running in the browser (window is defined)
    if (typeof window !== 'undefined') {
      search = window.location.search;
      const email = localStorage.getItem("email");
      encoded_email = email ? base64.encode(email) : "";
      qr_value = email 
        ? `https://app.family.one/home/homemain/#search_user?email=${encoded_email}` 
        : "https://app.family.one";
    }
  
    // Set state
    this.state = {
      search_string: search ? decodeURIComponent(search) : "",
      qr_value: qr_value,
      load: true,
      hasMounted: false,
    };
  }
  



  handleLogout = () => {
    let all_local_storage = this.allLocalStorage();

    all_local_storage.forEach((element, index) => {
      for (var key in element) {
        localStorage.removeItem(key)
      }
    });
    let all_cookie = cookie.loadAll();
    let all_cookie_length = Object.keys(all_cookie).length;
    let index = 0;
    for (var key in all_cookie) {
      cookie.remove(key, { path: "/" })
      index = index + 1;
      let local_storage = this.allLocalStorage();
      if (local_storage && local_storage.length == 0) {
        if (index == all_cookie_length) {
          localStorage.setItem("logged_in", 'false')
          let {history} = this.props
          if(history) history.push("/")
        }
      }
      else {
        this.handleLogout()
      }
    }
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }
  
  toggle = () => {
    this.setState({
      open_dailog: !this.state.open_dailog
    });
  }
  getHashParams() {
    const hash = window.location.hash.substring(1); // Remove the '#' character
    const params = {};
  
    hash.split("&").forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });
  
    return params;
  }

  componentDidMount = async () => {
    const hashParams = this.getHashParams();

    const accessToken = hashParams.access_token;
    const state = hashParams.state;

    try{
      // Fetch the authorization code from the query parameters
      // Extract current URL's search parameters
      const pixelId = config.social.FB_PIXEL;
      if (pixelId) {
        initFacebookPixel(pixelId); // Only runs on the client side
      }
      const params = new URLSearchParams(window.location.search);

      const utmSource = params.get('utm_source');
      const utmMedium = params.get('utm_medium');
    
      if (utmSource || utmMedium) {
        this.fetchDataAndSendToLambda();
      }

      // Get 'code' and 'state' from the URL
      const authorizationCode = params.get("code");
      const gState = params.get("state");

      if (accessToken && state) {
        // Save access token in localStorage
        let p_id = state.split("_").pop();
        localStorage.setItem('fb_entered_pid', p_id) 
        localStorage.setItem('fb_access_token', accessToken || '');

        // Dynamically build the redirect URI
        const redirectURI = `${window.location.origin}/${state}`;

        // Perform the redirection
        window.location.href = redirectURI;
      }else if(gState && authorizationCode){
        let p_id = gState.split("_").pop();
        localStorage.setItem('fb_entered_pid', p_id) 
        localStorage.setItem('google_access_code', authorizationCode || '');

        // Dynamically build the redirect URI
        const redirectURI = `${window.location.origin}/${gState}`;

        // Perform the redirection
        window.location.href = redirectURI;
      }else{
        let self = this
        this.setState({ hasMounted: true });
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        let search = window.location.search;

        let parameters = this.getUrlVars()
        if (parameters && parameters.phoneTransferRequest === 'abort') {
          let transfer_data = {
            "action": parameters.phoneTransferRequest,
            "id": parameters.id
          }
          
          let url = 'https://xy1uysx9qk.execute-api.us-east-1.amazonaws.com/prod/PhoneTransferHandler'
          
          axios.request({
            url: url,
            data: JSON.stringify(transfer_data),
            method: "post"
          }).then(function (response) {
            
            self.toggle();
            setTimeout(() => {
              self.setState({ open_dailog: false });
            }, 5000);
          });
        }
        if (parameters.sid) {
          cookie.save("session_id", parameters.sid, { path: "/" });
        }
        if (parameters.ref_nme) {
          cookie.save("http_referer", parameters.ref_nme, { path: "/" });
        } else {
          cookie.remove("http_referer", { path: "/" })
        }
        auto_id = auto_id ? auto_id : ""
        if (auto_id != "" && auto_id != "undefined") {
          functions.getLandingPage(this);
          cookie.save("auto_id", auto_id, { path: "/" });
          localStorage.setItem("auto_id", auto_id)
        }
        let session_auto_id = auto_id ? auto_id : null

        // let dynamic_content = localStorage.getItem("dynamic_content")?JSON.parse(localStorage.getItem("dynamic_content")):[]
        // if(dynamic_content && dynamic_content.length == 0){
        let location = window.location.pathname;
        let session_id = cookie.load("session_id") ? cookie.load("session_id") : ""
        const checkSession = () => {
          return new Promise((resolve) => {
            session.checkSession(session_auto_id, '', 'true');
            
            self.setState({ load: true });
            functions.getDynamicContent(function (response_json) {
              functions.Loadscript();
              resolve(); // Resolve the promise when done
            });
          });
        };
      
        // Run the checkSession function independently
        
        if ((session_id == "" || localStorage.getItem("current_state") == "main_home") && location == "/") {
          checkSession(); 
        }
        else {
          checkSession(); 
          // let finaljson = {
          //   email: email,
          //   sub_id: auto_id
          // }
          // functions.checkUserExistence(self, finaljson, function (response_json) {
          //   let response = JSON.parse(response_json)
          //   
          //   if (response.data && response.data.dynamo && response.data.dynamo.Items && response.data.dynamo.Items.length == 0) {
          //     functions.pushToRoute(self, "", "app")
          //   }
          // });
        }

        window.addEventListener("storage", (function (event) {
          if (event.key == 'logout-event') {
            // ..
            localStorage.clear()
            cookie.remove('auto_id', { path: "/" })
            this.props.history.push("/")
          }
        }).bind(this));

        if (this.state.search_string !== "") {
          let id = this.state.search_string.substring(0, 8)
          if (id == "?fbclid=") {
            window.location.search = ''
          }
          else if (id == "?Contest") {
            let final_search = this.state.search_string.split("&");
            let social_shared_prog_id = final_search[0].trim().split("=").pop();
            let social_shared_refered_id = final_search[1].trim().split("=").pop();
            let social_medium = final_search[2].trim().split("=").pop();
            cookie.save("social_medium", social_medium, { path: "/" });
            cookie.save("social_shared_prog_id", social_shared_prog_id, { path: "/" })
            cookie.save("social_shared_refered_id", social_shared_refered_id, { path: "/" })
            localStorage.removeItem("given_name")
            localStorage.removeItem("family_name")
            localStorage.removeItem("email")
            localStorage.removeItem("picture")
          }
          else {
          }
        }

      }
    }catch(error){
      console.error("Error during Facebook redirect handling:", error);
    }
    
    

  }

  // Function to get URL parameters and send data to Lambda (Fire-and-Forget)
fetchDataAndSendToLambda = () => {
  const urlParams = new URLSearchParams(window.location.search);
  
  // Extract the necessary data from the URL
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const ad = urlParams.get('ad');
  const programId = urlParams.get('program ID');
  const version = urlParams.get('version');
  const client = urlParams.get('Client');
  const pixel = urlParams.get('Pixel');

  const lambdaData = {
    utm_source: utmSource,
    utm_medium: utmMedium,
    camp_id: ad,
    program_id: programId,
    version: version,
    client: client,
    pixel: pixel,
  };

  // Fire-and-forget to Lambda
  const lambdaUrl = 'https://sxql70itni.execute-api.us-east-1.amazonaws.com/prod/fbCampTracking';
  
  // Send the request to Lambda asynchronously
  axios.post(lambdaUrl, lambdaData).then(result=>{
    const {camp_id, program_id} = result.data.item;
    if(camp_id) sessionStorage.setItem('fb_camp_id', camp_id)
    if(program_id) sessionStorage.setItem('fb_camp_program_id', program_id)
  }).catch(error => {
    console.error('Error sending data to Lambda:', error);
    
  });
};


  render() {
  const { Component, pageProps } = this.props;
  const { hasMounted } = this.state;
  // if (!hasMounted) return null;
    return (

        <Provider store={configureStore()}>

            <div className="container">
              <div>
                {/* <div className="onMobileShow"> */}
                {isMobile &&
                  <div >
                    {
                      this.state.load == true ?

                        <div>
                        <Component {...pageProps} />
                        </div> :
                        <div className="main_site_load">
                          <center>
                            {/* <CircularProgress className="loader_class" color="secondary" /> */}
                            <LottieLoader />
                          </center>
                        </div>
                    }
                  </div>
                }
                {!isMobile &&
                  <div className="onwebShow" >
                    <div className="AddsiteSidebar">
                      <div className="">
                        <div className="siteSidebarIner2">
                          <div >
                            {
                              this.state.load == true ?

                                <div>
                                <Component {...pageProps} />
                                </div> :
                                <div className="main_site_load">
                                  <center>
                                    {/* <CircularProgress className="loader_class" color="secondary" /> */}
                                    <LottieLoader />
                                  </center>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                      {
                        this.state.load == true &&
                        <div className="siteSidebar">
                          <h1><b>family.one</b> <br />
                            is designed for <br />
                            <strong>smartphones.</strong>
                          </h1>
                          <p>
                            You can <b>SCAN</b> this <b>QR Code</b> <br />
                            with your phone to continue <br />
                            your session there.
                          </p>
                          {/* <QRCode value="http://facebook.github.io/react/" /> */}
                          <div>
                            <QRCode style={{ height: "66px", width: "66px", position: "initial" }} value={this.state.qr_value} />
                            {/* <img src={require('../src/assets/images/barcodeNW.jpg')} alt="" title="" /> */}
                          </div>
                        </div>
                      }
                    </div>
                  </div>}

              </div>
              <div className="open_mail_model">
                <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className+" custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                    <ModalBody className="ntcBody_phoneTransferRequest">
                        <div className="ntvcontent_app padding_top">
                      
                            <img src='/images/closeChild.svg' alt="" title="" onClick={() => { this.toggle() }} className="ntcimgClose" />
                            
                            <div className="ntc_head">
                                <h3>Request Terminated!</h3>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
      
          </div>

      </Provider>

    );
  }
}
export default App;
